import React from 'react';
import Home from './Home';
import Generate from './Generate';
import Playground from './Playground';
import WorkflowPage from './WorkflowPage';
import WorkflowList from './WorkflowList';

const App = () => {
  return <h1>Hello, world.</h1>;
}

export { Home, Generate, Playground, WorkflowPage, WorkflowList };