import React, { useState, useEffect, useRef } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const Banner = ({ scrollToPosts }) => (
  <div
    className="bg-bg-secondary dark:bg-bg-tertiary min-h-[80vh] py-12 flex items-center relative overflow-hidden"
    style={{
      backgroundImage: "url('/static/site-images/warm-banner.jpg')",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className="absolute inset-0 bg-black opacity-70 pointer-events-none"></div>

    <div className="container mx-auto flex justify-center items-end relative z-10">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-white mb-4">Warm Diffusion</h1>
        <p className="text-2xl text-white mb-8">
          AI art tutorials, Flux AI guides, and Comfy UI workflows
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-white text-black font-semibold py-2 px-6 rounded-full hover:bg-gray-100 transition-colors duration-300"
            onClick={scrollToPosts} // Call the scrollToPosts function on click
          >
            See Tutorials
          </button>
          <button
            className="bg-white text-black font-semibold py-2 px-6 rounded-full hover:bg-gray-100 transition-colors duration-300"
            onClick={() => window.location.href = '/playground'}
          >
            Try ComfyUI Playground
          </button>
        </div>
      </div>
    </div>
  </div>
);

const BlogPost = ({ post }) => (
  <a href={`/blog/${post.slug}`} className="block group">
    <div className="bg-bg-secondary dark:bg-bg-tertiary p-6 rounded-lg shadow-md cursor-pointer transition duration-300">
      {post.featured_image && (
        <img
          src={post.featured_image}
          alt={`Featured image for ${post.title}`}
          className="w-full h-48 object-cover rounded-lg mb-4"
        />
      )}
      <h3 className="text-xl font-semibold text-text-primary mb-2 group-hover:text-button transition duration-300">
        {post.title}
      </h3>
      <p className="text-text-secondary mb-4 group-hover:text-button transition duration-300">
        {post.excerpt}
      </p>
    </div>
  </a>
);

const LatestPosts = React.forwardRef(({ posts }, ref) => (
  <div ref={ref} className="bg-bg-secondary dark:bg-bg-secondary">
    <div className="container mx-auto py-16">
      <h2 className="text-3xl font-bold text-text-primary mb-8">Latest Blog Posts</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {posts.map((post) => (
          <BlogPost key={post.id} post={post} />
        ))}
      </div>
    </div>
  </div>
));

const FluxBanner = () => (
  <div className="bg-button text-white overflow-hidden relative">
    <div className="container mx-auto flex items-center h-full">
      <div className="w-full md:w-1/2 py-8 md:py-16 px-4 md:px-8 relative z-10 text-center md:text-left">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Our ComfyUI Playground is LIVE!</h2>
        <p className="text-lg md:text-xl mb-6">
          Run FLUX models online without worrying about VGA power!
        </p>
        <button
          className="bg-white text-black font-semibold py-2 px-6 rounded-full hover:bg-gray-100 transition-colors duration-300"
          onClick={() => window.location.href = '/playground'}
        >
          Try it out
        </button>
      </div>
      <div className="hidden md:block absolute top-0 right-0 w-1/2 h-full">
        <div className="absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-button to-transparent z-10"></div>
        <img
          src="/static/site-images/capture-image.jpg"
          alt="AI Playground"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  </div>
);

export default function Home() {
  const [posts, setPosts] = useState([]);
  const latestPostsRef = useRef(null); // Create a ref for the Latest Posts section

  useEffect(() => {
    const fetchPosts = async () => {
      const csrfToken = getCsrfToken();
      try {
        const response = await fetch('/api/posts/', {
          headers: {
            'X-CSRFToken': csrfToken,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPosts(data.slice(0, 9)); // Get the latest 9 posts
        } else {
          console.error('Failed to fetch posts');
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const scrollToPosts = () => {
    if (latestPostsRef.current) {
      latestPostsRef.current.scrollIntoView({ behavior: 'smooth' }); // Smoothly scroll to Latest Posts
    }
  };

  return (
    <div className="min-h-screen">
      <Navbar />
      <Banner scrollToPosts={scrollToPosts} /> {/* Pass the scrollToPosts function to Banner */}
      <LatestPosts posts={posts} ref={latestPostsRef} /> {/* Attach ref to Latest Posts */}
      <FluxBanner />
      <Footer />
    </div>
  );
}

const getCsrfToken = () => {
  let csrfToken = null;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name === 'csrftoken') {
      csrfToken = value;
      break;
    }
  }
  return csrfToken;
};
