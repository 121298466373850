import React, { useState, useEffect } from 'react';
import { Sun, Moon, Plus, X, Menu } from 'lucide-react'; // Import Menu icon
import axios from 'axios';
import useStore from '../useStore';

const getCsrfToken = () => {
  let csrfToken = null;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name === 'csrftoken') {
      csrfToken = value;
      break;
    }
  }
  return csrfToken;
};

export default function Navbar() {
  const [darkMode, setDarkMode] = useState(false);
  const [username, setUsername] = useState(null);
  const { credits, fetchUserDetails, setCredits } = useStore(); // Destructure fetchUserDetails from useStore
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // New state for mobile menu

  const [quantity800, setQuantity800] = useState(1);
  const [quantity1600, setQuantity1600] = useState(1);
  const [quantity3500, setQuantity3500] = useState(1);
  const [loading, setLoading] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);

  const handleCheckout = async (credit_pack, quantity = 1) => {
    const csrfToken = getCsrfToken();
    setLoading(true);

    try {
      const response = await fetch('/create-checkout-url/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({
          credit_pack,
          quantity,
        }),
      });

      const data = await response.json();

      if (data.checkout_url) {
        setIframeUrl(data.checkout_url);
        setIframeLoading(true);
        setIframeOpen(true);
        setModalOpen(false);
      } else {
        console.error('Error: Checkout URL not returned');
      }
    } catch (error) {
      console.error('Error during checkout URL request:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handlers for incrementing and decrementing quantity
  const incrementQuantity = (setQuantity, currentQuantity) => {
    if (currentQuantity < 10) {
      setQuantity(currentQuantity + 1);
    }
  };

  const decrementQuantity = (setQuantity, currentQuantity) => {
    if (currentQuantity > 1) {
      setQuantity(currentQuantity - 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/user-details/');
        if (response.data.username) {
          setUsername(response.data.username);
        }
        if (response.data.credits !== undefined) {
          setCredits(response.data.credits); // Update the credits state in the store
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    };

    fetchData(); // Fetch user details once on component mount

    const savedTheme = localStorage.getItem('theme');
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (savedTheme === 'dark' || (!savedTheme && prefersDark)) {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
    } else {
      setDarkMode(false);
      document.documentElement.classList.remove('dark');
    }
  }, [setCredits]);

  useEffect(() => {
    console.log('Credits updated:', credits); // Log the updated credits value
  }, [credits]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      if (newMode) {
        document.documentElement.classList.add('dark');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('theme', 'light');
      }
      return newMode;
    });
  };

  const handleProfileClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCreditsClick = () => {
    setModalOpen(true);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen); // Toggle the mobile menu
  };

  return (
    <nav className="bg-bg-secondary dark:bg-bg-primary p-4">
      <div className="mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center space-x-2">
          <a href="/" className="flex items-center">
            <img src="/static/logo/wd-logo.png" alt="Warm Diffusion Logo" className="h-8 w-auto mr-2" />
            <span className="text-2xl font-bold text-text-primary">Warm Diffusion</span>
          </a>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-6">
          <a href="/" className="text-text-primary hover:text-button">Tutorials</a>
          <a href="/workflows" className="text-text-primary hover:text-button">Workflows</a>
          {username ? (
            <div className="relative flex items-center">
              <div
                onClick={handleProfileClick}
                className="bg-button text-white rounded-full w-8 h-8 flex items-center justify-center cursor-pointer hover:bg-button-hover transition-colors duration-200"
              >
                {username.charAt(0).toUpperCase()}
              </div>

              {menuOpen && (
                <div className="absolute right-0 top-full mt-2 w-48 bg-bg-secondary dark:bg-bg-primary shadow-lg rounded-md py-2 z-50">
                  <button
                    onClick={() => (window.location.href = '/accounts/logout')}
                    className="block px-4 py-2 text-left text-text-primary hover:bg-bg-tertiary w-full transition-colors duration-200"
                  >
                    Logout
                  </button>
                </div>
              )}
              <div
                onClick={handleCreditsClick}
                className="ml-3 flex items-center border border-button rounded-md overflow-hidden cursor-pointer hover:border-button-hover transition-colors duration-200"
              >
                <span className="px-3 py-1.5 text-text-primary">{credits ?? '0'}</span> {/* Display credits */}
                <div className="bg-button hover:bg-button-hover text-white p-2 transition-colors duration-200">
                  <Plus className="w-5 h-5" />
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={() => (window.location.href = '/accounts/login')}
              className="text-button border border-button bg-transparent hover:bg-button hover:text-white font-bold py-2 px-4 rounded transition-colors duration-200"
            >
              Sign In
            </button>
          )}
          <button
            onClick={() => (window.location.href = '/playground')}
            className="bg-button hover:bg-button-hover text-white px-4 py-2 rounded transition-colors duration-200"
          >
            Try ComfyUI Playground
          </button>
          <button onClick={toggleDarkMode} className="text-text-primary hover:text-button transition-colors duration-200">
            {darkMode ? <Sun className="w-6 h-6" /> : <Moon className="w-6 h-6" />}
          </button>
        </div>

        {/* Mobile Menu Icon */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMobileMenu} className="text-text-primary">
            {mobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="md:hidden flex flex-col mt-4 space-y-2">
          <a href="/" className="text-text-primary hover:text-button">Tutorials</a>
          {username ? (
            <div className="flex flex-col space-y-2">
              <button
                onClick={() => (window.location.href = '/accounts/logout')}
                className="text-left text-text-primary hover:bg-bg-tertiary w-full px-4 py-2 transition-colors duration-200"
              >
                Logout
              </button>
              <button
            onClick={() => (window.location.href = '/playground')}
            className="bg-button hover:bg-button-hover text-white px-4 py-2 rounded transition-colors duration-200"
          >
            Try ComfyUI Playground
          </button>
            </div>
          ) : (
            <button
              onClick={() => (window.location.href = '/accounts/login')}
              className="bg-button hover:bg-button-hover text-white px-4 py-2 rounded transition-colors duration-200"
            >
              Sign In
            </button>
          )}
          <button onClick={toggleDarkMode} className="text-text-primary hover:text-button transition-colors duration-200">
            {darkMode ? <Sun className="w-6 h-6" /> : <Moon className="w-6 h-6" />}
          </button>
        </div>
      )}

      {/* Modal for Credits */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-bg-secondary dark:bg-bg-primary p-6 rounded-lg shadow-xl max-w-3xl w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-text-primary">Buy Credits</h2>
              <button onClick={() => setModalOpen(false)} className="text-text-secondary hover:text-text-primary">
                <X className="w-6 h-6" />
              </button>
            </div>
            <p className="text-text-primary mb-4">You currently have {credits} credits available. Once purchased please refresh the page to see credits.</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="border border-border rounded-lg p-4 flex flex-col items-center">
                <h3 className="text-xl font-semibold text-text-primary mb-2">800 credits</h3>
                <p className="text-3xl font-bold text-text-primary mb-2">$3.99</p>
                <p className="text-text-secondary font-bold mb-4">~0.004 per credit</p>
                <button
                  onClick={() => handleCheckout(800, quantity800)}
                  className="bg-button hover:bg-button-hover text-white px-4 py-2 rounded transition-colors duration-200 w-full"
                >
                  Buy Now
                </button>
              </div>
              <div className="border border-border rounded-lg p-4 flex flex-col items-center">
                <h3 className="text-xl font-semibold text-text-primary mb-2">1600 credits</h3>
                <p className="text-3xl font-bold text-text-primary mb-2">$6.99</p>
                <p className="text-text-secondary font-bold mb-4">~0.003 per credit</p>
                <button
                  onClick={() => handleCheckout(1600, quantity1600)}
                  className="bg-button hover:bg-button-hover text-white px-4 py-2 rounded transition-colors duration-200 w-full"
                >
                  Buy Now
                </button>
              </div>
              <div className="border border-border rounded-lg p-4 flex flex-col items-center">
                <h3 className="text-xl font-semibold text-text-primary mb-2">3500 credits</h3>
                <p className="text-3xl font-bold text-text-primary mb-2">$12.99</p>
                <p className="text-text-secondary font-bold mb-4">~0.002 per credit</p>
                <button
                  onClick={() => handleCheckout(3500, quantity3500)}
                  className="bg-button hover:bg-button-hover text-white px-4 py-2 rounded transition-colors duration-200 w-full"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Iframe Overlay */}
      {iframeOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darkened background effect
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // Make sure it's on top of everything else
          }}
        >
          {iframeLoading && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1001,
              }}
            >
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  border: `6px solid var(--mantinebutton-color)`,
                  borderTopColor: 'transparent',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite',
                }}
              ></div>
            </div>
          )}
          <iframe
            src={iframeUrl}
            title="Checkout"
            style={{ width: '100%', height: '100%', border: 'none', backgroundColor: 'transparent' }}
            onLoad={() => setIframeLoading(false)}
          />
          <button
            onClick={() => setIframeOpen(false)}
            className="bg-button hover:bg-button-hover text-white px-4 py-2 rounded transition-colors duration-200 absolute top-2.5 right-2.5"
          >
            X
          </button>
        </div>
      )}
    </nav>
  );
}
