import React from 'react'
import { Youtube } from 'lucide-react'

const Footer = () => (
  <footer className="bg-bg-secondary dark:bg-bg-primary py-8">
    <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
      <div className="text-text-secondary mb-4 md:mb-0">
        © 2024 Warm Diffusion. All rights reserved.
      </div>
      <div className="flex space-x-6">
        <a href="/tos" className="text-text-secondary hover:text-button">Terms of Service</a>
        <a href="/privacy-policy" className="text-text-secondary hover:text-button">Privacy Policy</a>
        <a href="https://www.youtube.com/@warmdiffusion" className="text-text-secondary hover:text-button">
          <Youtube className="w-6 h-6" />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer