import { create } from 'zustand';
import axios from 'axios';

const useStore = create((set) => ({
  credits: null,  // Initialize credits state
  setCredits: (credits) => set({ credits }),  // Action to set credits

  // Fetch user details and update credits
  fetchUserDetails: async () => {
    try {
      const response = await axios.get('/api/user-details/');
      if (response.data.credits !== undefined) {
        set({ credits: response.data.credits });
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  }
}));

export default useStore;
