import React from 'react';
import { AlertTriangle } from 'lucide-react';

export default function ErrorMessage({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-bg-primary rounded-lg shadow-lg p-6 max-w-sm text-center">
        <div className="flex items-center justify-center mb-4">
          <AlertTriangle className="text-red-500 w-8 h-8 mr-2" />
          <span className="text-lg font-semibold text-red-500">Error</span>
        </div>
        <p className="text-white mb-4">{message}</p>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none"
        >
          OK
        </button>
      </div>
    </div>
  );
}
