import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../css/app.css';

import { Home, Generate, Playground, WorkflowPage, WorkflowList } from './app';
import Navbar from './components/Navbar';

const homeContainer = document.getElementById('home-root');
const generateContainer = document.getElementById('generate-root');
const playgroundContainer = document.getElementById('playground-root');
const workflowContainer = document.getElementById('workflow-root');
const workflowListContainer = document.getElementById('workflow-list-root');


if (homeContainer) {
  const root = createRoot(homeContainer);
  root.render(
    <Home />
  );
}

if (generateContainer) {
  const root = createRoot(generateContainer);
  root.render(
    <Generate />
  );
}

if (playgroundContainer) {
  const root = createRoot(playgroundContainer);
  root.render(
    <>
    <Navbar />
    <Playground />
    </>
  );
}

if (workflowContainer) {
  const root = createRoot(workflowContainer);
  root.render(
    <>
    <Navbar />
    <WorkflowPage />
    </>
  );
}

if (workflowListContainer) {
  const root = createRoot(workflowListContainer);
  root.render(
    <>
    <Navbar />
    <WorkflowList />
    </>
  );
}