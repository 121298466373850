import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Position,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Navbar from './components/Navbar';
import { X } from 'lucide-react';
import ErrorMessage from './components/ErrorMessage';
import useStore from './useStore';

const getCsrfToken = () => {
  let csrfToken = null;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name === 'csrftoken') {
      csrfToken = value;
      break;
    }
  }
  return csrfToken;
};

const ImageOverlay = ({ image, onClose }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = image;
    link.download = 'generated-image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 bg-opacity-75 flex items-center justify-center z-50" style={{ backgroundColor: 'var(--color-bg-primary)' }}>
      <div className="relative max-w-4xl max-h-full">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 hover:opacity-75 focus:outline-none"
          style={{ color: 'var(--color-text-primary)' }}
        >
          <X size={24} />
        </button>
        <img src={image} alt="Full size artwork" className="max-w-full max-h-[calc(100vh-8rem)] object-contain" />
        <button
          onClick={handleDownload}
          className="mt-4 w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          style={{
            backgroundColor: 'var(--color-button)',
            color: 'var(--color-text-primary)',
          }}
        >
          Download Image
        </button>
      </div>
    </div>
  );
};

const InputBox = ({ title, children }) => (
  <div className="p-3 rounded-lg mb-3" style={{ backgroundColor: 'var(--color-bg-secondary)' }}>
    <h3 className="font-bold mb-2 text-sm" style={{ color: 'var(--color-text-primary)' }}>{title}</h3>
    {children}
  </div>
);

// Custom Node Components
// Custom Node Components
const PromptNode = ({ data }) => (
  <div className="p-3 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)', width: 250 }}>
    <Handle type="source" position={Position.Bottom} />
    <InputBox title="CLIP Text Encode">
      <textarea
        onChange={(e) => {
          e.stopPropagation();
          data.setPrompt(e.target.value);
        }}
        onClick={(e) => e.stopPropagation()}
        placeholder="Enter your prompt here"
        rows={6}
        className="w-full p-2 rounded"
        style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
      />
    </InputBox>
  </div>
);

const DualClipNode = ({ data }) => (
  <div className="p-3 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)', width: 250 }}>
    <Handle type="target" position={Position.Top} />
    <Handle type="source" position={Position.Bottom} />
    <InputBox title="DualCLIP Loader">
      <div className="grid grid-cols-2 gap-2">
        <div>
          <label htmlFor="clip1" className="block text-xs font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>clip_name1</label>
          <select
            id="clip1"
            onChange={(e) => {
              e.stopPropagation();
              data.setClip1(e.target.value);
            }}
            onClick={(e) => e.stopPropagation()}
            className="w-full p-1 rounded text-sm"
            style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
          >
            <option value="t5xxl_fp8_e4m3fn.safetensors">t5xxl_fp8_e4m3fn.safetensors</option>
            <option value="clip_l.safetensors">clip_l.safetensors</option>
          </select>
        </div>
        <div>
          <label htmlFor="clip2" className="block text-xs font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>clip_name2</label>
          <select
            id="clip2"
            onChange={(e) => {
              e.stopPropagation();
              data.setClip2(e.target.value);
            }}
            onClick={(e) => e.stopPropagation()}
            className="w-full p-1 rounded text-sm"
            style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
          >
            <option value="t5xxl_fp8_e4m3fn.safetensors">t5xxl_fp8_e4m3fn.safetensors</option>
            <option value="clip_l.safetensors">clip_l.safetensors</option>
          </select>
        </div>
      </div>
    </InputBox>
  </div>
);

const SamplerNode = ({ data }) => (
  <div className="p-3 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)', width: 250 }}>
    <Handle type="target" position={Position.Top} />
    <Handle type="source" position={Position.Bottom} />
    <InputBox title="Sampler Select">
      <select
        onChange={(e) => {
          e.stopPropagation();
          data.setSampler(e.target.value);
        }}
        onClick={(e) => e.stopPropagation()}
        className="w-full p-2 rounded text-sm"
        style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
      >
        <option value="euler">euler</option>
        <option value="euler a">euler a</option>
      </select>
    </InputBox>
  </div>
);

const SchedulerNode = ({ data }) => {
  return (
    <div className="p-3 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)', width: 250 }}>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <InputBox title="BasicScheduler">
        <label htmlFor="steps" className="block text-xs font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>
          Steps:
        </label>
        <input
          id="steps"
          type="number"
          onChange={(e) => {
            e.stopPropagation();
            data.setSteps(e.target.value);  // Text box accepts any number
          }}
          className="w-full p-2 rounded text-sm"
          style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
        />
      </InputBox>
    </div>
  );
};

// GuidanceNode Component with plain text input
const GuidanceNode = ({ data }) => {
  return (
    <div className="p-3 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)', width: 250 }}>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <InputBox title="FluxGuidance">
        <label htmlFor="guidance" className="block text-xs font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>
          Guidance:
        </label>
        <input
          id="guidance"
          type="number"
          onChange={(e) => {
            e.stopPropagation();
            data.setGuidance(e.target.value);  // Text box accepts any number
          }}
          className="w-full p-2 rounded text-sm"
          style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
        />
      </InputBox>
    </div>
  );
};

const LatentImageNode = ({ data }) => (
  <div className="p-3 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)', width: 250 }}>
    <Handle type="target" position={Position.Top} />
    <Handle type="source" position={Position.Bottom} />
    <InputBox title="EmptySD3LatentImage">
      <select
        onChange={(e) => {
          e.stopPropagation();
          data.setAspectRatio(e.target.value);
        }}
        onClick={(e) => e.stopPropagation()}
        className="w-full p-2 rounded text-sm"
        style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
      >
        {Object.entries(data.aspectRatios).map(([ratio, { width, height }]) => (
          <option key={ratio} value={ratio}>
            {ratio} ({width}px × {height}px)
          </option>
        ))}
      </select>
    </InputBox>
  </div>
);

const RandomNoiseNode = ({ data }) => {
    const [selectedSeedType, setSelectedSeedType] = useState("");

    return(
        <div className="p-3 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)', width: 250 }}>
        <Handle type="target" position={Position.Top} />
        <InputBox title="RandomNoise">
          <div className="flex items-center space-x-4 mb-2">
            <label className="block text-xs font-medium mb-1" style={{ color: 'var(--color-text-primary)' }}>
              Seed Type
            </label>
            <select
              onChange={(e) => {
                e.stopPropagation();
                setSelectedSeedType(e.target.value);  // Update local state
                data.setSeedType(e.target.value);  // Make sure this updates correctly
              }}
              className="w-full p-2 rounded text-sm"
              style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
            >
              <option value="random">Random</option>
              <option value="fixed">Fixed</option>
            </select>
          </div>

          {selectedSeedType  === "fixed" && (  // Conditional rendering based on seedType value
            <div>
              <label htmlFor="fixedSeed" className="block text-xs font-medium mb-1" style={{ color: 'var(--color-text-secondary)' }}>Seed</label>
              <input
                id="fixedSeed"
                type="number"
                onChange={(e) => {
                  e.stopPropagation();
                  data.setFixedSeed(e.target.value);
                }}
                onClick={(e) => e.stopPropagation()}
                min="10000000000000"
                max="999999999999999"
                className="w-full p-2 rounded text-sm"
                style={{ backgroundColor: 'var(--color-bg-primary)', color: 'var(--color-text-primary)', border: '1px solid var(--color-border)' }}
              />
            </div>
          )}
        </InputBox>
      </div>
    );

    }




const nodeTypes = {
  prompt: PromptNode,
  dualClip: DualClipNode,
  sampler: SamplerNode,
  scheduler: SchedulerNode,
  guidance: GuidanceNode,
  latentImage: LatentImageNode,
  randomNoise: RandomNoiseNode,
};

const WorkflowViewer = ({ onGenerateImage, generatedImages, isLoading  }) => {
  const [prompt, setPrompt] = useState("");
  const [clip1, setClip1] = useState("t5xxl_fp8_e4m3fn.safetensors");
  const [clip2, setClip2] = useState("clip_l.safetensors");
  const [sampler, setSampler] = useState("euler");
  const [steps, setSteps] = useState(20);
  const [guidance, setGuidance] = useState(3.5);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [selectedImage, setSelectedImage] = useState(null);
  const [seedType, setSeedType] = useState("random");
  const [fixedSeed, setFixedSeed] = useState("152328926557295");

  const aspectRatios = {
    "1:1": { width: 1024, height: 1024 },
    "4:5": { width: 768, height: 960 },
    "2:3": { width: 768, height: 1152 },
    "9:16": { width: 720, height: 1280 },
    "4:7": { width: 768, height: 1344 },
    "5:4": { width: 960, height: 768 },
    "3:2": { width: 1152, height: 768 },
    "16:9": { width: 1280, height: 720 },
  };

  const initialNodes = [
    { id: '1', type: 'prompt', position: { x: 0, y: 0 }, data: { prompt, setPrompt } },
    { id: '2', type: 'dualClip', position: { x: 0, y: 250 }, data: { clip1, setClip1, clip2, setClip2 } },
    { id: '3', type: 'sampler', position: { x: 0, y: 500 }, data: { sampler, setSampler } },
    { id: '4', type: 'scheduler', position: { x: 300, y: 0 }, data: { steps, setSteps } },
    { id: '5', type: 'guidance', position: { x: 300, y: 250 }, data: { guidance, setGuidance } },
    { id: '6', type: 'latentImage', position: { x: 300, y: 500 }, data: { aspectRatio, setAspectRatio, aspectRatios } },
    { id: '7', type: 'randomNoise', position: { x: 600, y: 0 }, data: { seedType, setSeedType, fixedSeed, setFixedSeed } },
  ];

  const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' },
    { id: 'e2-3', source: '2', target: '3' },
    { id: 'e3-4', source: '3', target: '4' },
    { id: 'e4-5', source: '4', target: '5' },
    { id: 'e5-6', source: '5', target: '6' },
    { id: 'e6-7', source: '6', target: '7' },
  ];

  const [nodes,   setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const handleGenerateClick = () => {
    const { width, height } = aspectRatios[aspectRatio];
    const seed = seedType === "fixed" ? parseInt(fixedSeed) : Math.floor(Math.random() * (999999999999999 - 10000000000000 + 1)) + 10000000000000;
    onGenerateImage({
      prompt,
      clip1,
      clip2,
      sampler,
      steps,
      guidance,
      width,
      height,
      seed,
    });
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-[calc(100vh-4rem)]">
      <div className="w-full lg:w-4/5 h-full">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
      <div className="w-full lg:w-1/5 h-full overflow-y-auto p-4" style={{ backgroundColor: 'var(--color-bg-tertiary)' }}>
        <button
          onClick={handleGenerateClick}
          className={`w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm mb-4 relative overflow-hidden ${isLoading ? 'cursor-not-allowed opacity-75' : ''}`}
          style={{
            backgroundColor: 'var(--color-button)',
            color: 'var(--color-text-primary)',
          }}
          disabled={isLoading}
        >
          {isLoading && (
            <span className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-50 animate-progress-bar"></span>
          )}
          {isLoading ? 'Generating...' : 'Generate Image'}
        </button>
        <div className="border rounded-lg overflow-hidden" style={{ borderColor: 'var(--color-border)', backgroundColor: 'var(--color-bg-tertiary)' }}>
          <div className="overflow-y-auto h-full p-4">
            {generatedImages.length > 0 ? (
              <div className="grid grid-cols-2 gap-4">
                {generatedImages.map((image, index) => (
                  <div key={index} className="h-32 overflow-hidden rounded-lg cursor-pointer" onClick={() => setSelectedImage(image)}>
                    <img
                      src={image}
                      alt={`Generated artwork ${index + 1}`}
                      className="w-full h-full object-contain"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center py-4" style={{ color: 'var(--color-text-secondary)' }}>No images generated yet</p>
            )}
          </div>
        </div>
      </div>
      {selectedImage && (
        <ImageOverlay image={selectedImage} onClose={() => setSelectedImage(null)} />
      )}
    </div>
  );
};

export default function Generate() {
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const blobUrlsRef = useRef([]);
  const { fetchUserDetails } = useStore();

  const handleGenerateImage = async ({ prompt, clip1, clip2, sampler, steps, guidance, width, height, seed }) => {
    setIsLoading(true);
    setError(null);

    const csrfToken = getCsrfToken();

    try {
      const response = await fetch('/api/generate_artwork/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({
          prompt,
          clip1,
          clip2,
          sampler,
          steps,
          guidance,
          width,
          height,
          seed,
        }),
      });

      // Parse the JSON response
      const data = await response.json();

      // Check for non-OK responses (including 400 or 500)
      if (!response.ok) {
        // Check if backend provided an error message in the response
        if (data.error) {
          throw new Error(data.error); // Throw error with the message from backend
        } else {
          throw new Error(`HTTP error: ${response.status}`); // Generic error if no error message in body
        }
      }

      // Success case
      if (data && data.base64_image) {
        const blobUrl = await base64ToBlob(data.base64_image);
        blobUrlsRef.current.push(blobUrl);
        setGeneratedImages((prevImages) => {
          const newImages = [blobUrl, ...prevImages];
          return newImages.slice(0, 30); // Keep only the last 30 images
        });
        // Refresh credits after image generation
        await fetchUserDetails();
      } else {
        throw new Error('No image data received from the server');
      }
    } catch (err) {
      // Set the error message for display
      setError(`Error: ${err.message || err}`);
      console.error('Error generating image:', err); // Log for debugging
    } finally {
      setIsLoading(false);
    }
  };

  const base64ToBlob = async (base64) => {
    const response = await fetch(`data:image/png;base64,${base64}`);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  useEffect(() => {
    // Clean up blob URLs when component unmounts
    return () => {
      blobUrlsRef.current.forEach(URL.revokeObjectURL);
    };
  }, []);

  return (
    <div className="min-h-screen" style={{ backgroundColor: 'var(--color-bg-primary)' }}>
      <Navbar />
      <div className="px-1 py-8">
        <WorkflowViewer
          onGenerateImage={handleGenerateImage}
          generatedImages={generatedImages}
          isLoading={isLoading}
        />
        {isLoading && <p className="mt-4" style={{ color: 'var(--color-text-primary)' }}>Generating image...</p>}
        {error && (
          <ErrorMessage message={error} onClose={() => setError(null)} /> // Display error message
        )}
      </div>
    </div>
  );
}
