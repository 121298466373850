import React, { useState } from 'react';

export default function Playground() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="flex h-screen bg-bg-primary text-text-primary">
      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        {/* Header Banner - Full Width */}
        <div className="relative h-48 bg-cover bg-center flex items-center justify-center"
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1518432031352-d6fc5c10da5a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1024&q=80')",
          }}
        >
          {/* Dark Overlay */}
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <h1 className="relative text-4xl font-bold text-white z-10">
            Try any ComfyUI Workflow
          </h1>
        </div>

        {/* Workflows Section - Max Width Container */}
        <div className="max-w-screen-xl mx-auto p-8">
          <h2 className="text-2xl font-semibold mb-6">Workflows</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Simple Flux Workflow Card */}
            <a
              href="/playground/simple-flux"
              className="block bg-bg-secondary rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105"
            >
              <img
                src="https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=200&q=80"
                alt="Simple Flux Workflow"
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 transition-colors duration-300 hover:text-button">
                  Simple Flux Workflow
                </h3>
                <p className="text-text-secondary">
                  A straightforward Flux workflow for your projects.
                </p>
              </div>
            </a>

            {/* Flux Lora Tester Card */}
            <a
              className="block bg-bg-secondary rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105"
            >
              <img
                src="https://images.unsplash.com/photo-1620712943543-bcc4688e7485?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=200&q=80"
                alt="Flux Lora Tester"
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 transition-colors duration-300 hover:text-button">
                  Flux Lora Tester (Coming Soon...)
                </h3>
                <p className="text-text-secondary">
                  Test your Lora models with this Flux workflow.
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
