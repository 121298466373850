import React from 'react';

const WorkflowPage = () => {
  // Retrieve workflow data from the DOM (injected via Django template)
  const workflowDataElement = document.getElementById('workflow-data');
  const workflow = JSON.parse(workflowDataElement.textContent);

  return (
      <div className="bg-bg-secondary p-6">
    <div className="container mx-auto p-6 bg-bg-primary text-text-primary">
      <div className="flex flex-col lg:flex-row">
        {/* Main Content */}
        <div className="w-full lg:w-3/4">
          {/* Title */}
          <h1 className="text-4xl font-bold text-center mb-6 text-text-primary">{workflow.title}</h1>

          {/* Featured Image */}
          {workflow.featured_image && (
            <div className="flex justify-center mb-6">
              <img
                src={workflow.featured_image}
                alt="Featured"
                className="w-full lg:w-3/4 rounded shadow-lg border border-border"
              />
            </div>
          )}

          {/* Description */}
          <div
              className="prose max-w-full text-left text-text-primary"
              dangerouslySetInnerHTML={{ __html: workflow.description }}
            />
        </div>

        {/* Sidebar */}
        <div className="w-full lg:w-1/4 lg:pl-6 mt-6 lg:mt-0">
          {/* Download Section */}
          {workflow.json_file && (
            <div className="bg-bg-secondary rounded-lg shadow-md p-4 mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-text-primary">Download</h2>
              <a
                href={workflow.json_file}
                download
                className="inline-block bg-button hover:bg-button-hover text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Download Workflow
              </a>
            </div>
          )}

          {/* Original Author Section */}
          <div className="bg-bg-secondary rounded-lg shadow-md p-4">
            <h2 className="text-2xl font-semibold mb-4 text-text-primary">Original Author</h2>
            <p className="text-lg text-text-secondary">{workflow.author}</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default WorkflowPage;
