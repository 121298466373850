import React, { useState, useEffect } from 'react';

export default function WorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/workflows/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setWorkflows(data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="min-h-screen bg-bg-primary">
      <div className="w-full bg-bg-secondary py-4">
        <h1 className="text-3xl font-bold text-text-primary text-center">Workflow Gallery</h1>
      </div>
      <div className="container mx-auto px-4 py-8">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-button"></div>
          </div>
        ) : error ? (
          <div className="text-center text-red-500">Error: {error}</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {workflows.map(workflow => (
              <a
                key={workflow.id}
                href={`/workflows/${workflow.id}/${workflow.slug}`}
                className="block bg-bg-secondary rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl"
              >
                <div className="relative pb-[100%]">
                  {workflow.featured_image ? (
                    <img
                      src={workflow.featured_image}
                      alt={workflow.title}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                  ) : (
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-bg-tertiary text-text-secondary">
                      No Image
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h2 className="text-lg font-semibold text-text-primary transition-colors duration-300 hover:text-button">
                    {workflow.title}
                  </h2>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}